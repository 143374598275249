<template>
  <div class="page_container">
    <section class="row center">
      <div class="pt-35 pb-5 w600 row space-between">
        <h1 class="my-0">View user details</h1>
        <router-link
          :to="'/admin/partner/id/' + user.companyId"
          class="row align-end gap-10 mb-2"
        >
          <s-icon color="white" height="20">chevron-left</s-icon>
          <div class="my-0">Back</div>
        </router-link>
      </div>
    </section>
    <hr class="mt-15" />
    <section class="center py-50 col">
      <div class="w600 weight-300">Viewing user details</div>
    </section>

    <section class="w600 mx-auto mb-20">
      <div v-for="(value, key) in user" :key="key">
        <div
          v-if="value !== '' && value !== null && value !== undefined"
          class="grid"
        >
          <span class="label">{{ humanize(key) }}</span>
          <span class="item" :title="value">{{ formatValue(value, key) }}</span>
        </div>
      </div>
    </section>

    <section class="w600 mx-auto mt-20 mb-50 row gap-20 space-between">
      <s-btn @click.native="generateReport" class="green">
        Download / print
      </s-btn>
      <router-link :to="'/admin/partner/id/' + user.companyId" class="green">
        <s-btn class="green">close</s-btn>
      </router-link>
    </section>

    <vue-html2pdf
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="Administration"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <section class="w600 mx-auto mb-20 black--text pt-50">
          <div v-for="(value, key) in user" :key="key">
            <div v-if="value !== '' && value !== null && value !== undefined" class="grid">
              <span class="label">{{ humanize(key) }}</span>
              <span class="item">{{ formatValue(value, key) }}</span>
            </div>
          </div>
        </section>
      </section>
    </vue-html2pdf>
  </div>
</template>
    
    <script>
import { mapState } from "vuex";
import VueHtml2pdf from "vue-html2pdf";
import dayjs from "dayjs";
export default {
  components: {
    VueHtml2pdf,
  },
  data() {
    return {};
  },
  methods: {
    formatCurrency(x) {
      x = Number(x).toFixed(2);
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return "$ " + parts.join(".");
    },
    formatDate(val) {
      return dayjs(val).format("D MMMM YYYY");
    },
    formatValue(val, key) {
      let options = [
        "lastLogin",
        "updatedAt",
        "createdAt",
        "accountDeactivated",
      ];
      if (val === true) return "Yes";
      if (val === false) return "No";
      if (options.includes(key)) return this.formatDate(val);
      return val;
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    humanize(text) {
      const result = text.replace(/([A-Z])/g, " $1");
      return result.charAt(0).toUpperCase() + result.slice(1);
    },
  },
  computed: {
    ...mapState("admin", ["user"]),
  },
};
</script>
    
  <style lang="less" scoped>
.label {
  background-color: rgba(0, 0, 0, 0.35);
  padding: 8px;
  border-bottom: 1px solid hsla(208, 18%, 31%, 0.7);
  display: flex;
  align-items: center;
  opacity: 0.8;
  font-size: 14px;
}

.item {
  display: flex;
  align-items: center;
  padding-left: 8px;
  border-bottom: 1px solid hsla(208, 18%, 31%, 0.7);
  border-right: 1px solid hsla(208, 18%, 31%, 0.7);
  overflow: hidden;
  font-size: 14px;
  font-weight: 300;
}

.grid {
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-column-gap: 2px;
  width: 100%;
  transition: 0.2s all ease;
  border-top: 1px solid hsla(208, 18%, 31%, 0.7);
  min-height: 50px;
}
</style>